import React   from 'react';

import './WelcomeSection.css'; // Import CSS for styling
import Footer from '../Footer/Footer.tsx';
import "./GivaEssentials.css";
import Bangles from '../Jewelleryimg/Bnglesimg.JPG';
import Earring from '../Jewelleryimg/Earing.JPG';
import Necklace from '../Jewelleryimg/Necklaceset.jpg';
const WelcomeSection = () => {
 
  return (
    <div>
          <section className="welcome-section">
      <div className="overlay">
        <div className="welcome-content">
          <h1>Welcome to</h1>
          <h2>Afro Asiatic Fusion Accessories</h2>
          <p className="location">D U B A I</p>
        </div>
      </div>
    </section>
    <section className="about-section">
  <div className="about-container">
    {/* <!-- Left Text Section --> */}
    <div className="about-text">
      <h1>Afro Asiatic Fusion Accessories</h1>
      <p>
        Manufacturer, wholesaler, exporter, supplier, stockist of high-quality fashion jewellery. We provide exquisite jewellery from highly talented designers. We bring you a wide range of designer fusion art jewellery, which is a blend of superior quality, beautiful design & excellent craftsmanship. Our collection of fashionable jewellery has been designed by expert designers and handcrafted by the most talented artisans.
      </p>
      <ul>
        <li>Afroasiatic Fusion Art Jewellery offers a wide variety of exclusive rings.</li>
        <li>Breakdown due to undertaking Afro Art Fashion Jewellery Latest Design.</li>
      </ul>
    </div>

    {/* <!-- Right Image Section --> */}
    <div className="about-image">
        <img src='http://www.afroasiaticfusionartjewellerydubai.com/princia.jpeg' alt='Ms. Princia Rodrigues' />
     {/* <img src="your-image-url" alt="Ms. Princia Rodrigues"> */}
      {/* <h3>Ms. Princia Rodrigues</h3>
      <p>Head of Fashion Jewellery</p> */}
    </div>
  </div>
</section>

<div className="jewellery-section">
      <h3>WHY US</h3>
      <h2>Why Choose Our Jewellery</h2>
      <div className="jewellery-items">
        <div className="jewellery-item">
          {/* Bangles SVG */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="jewellery-icon">
            <circle cx="12" cy="12" r="8" stroke="gold" strokeWidth="2" />
            <rect x="8" y="8" width="8" height="8" fill="pink" />
          </svg> */}
          <img src={Bangles} className="jewellery-icon"/>
          <p>BANGLES</p>
        </div>

        <div className="jewellery-item">
          {/* Ring SVG */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="jewellery-icon">
            <circle cx="8" cy="12" r="6" stroke="gold" strokeWidth="2" />
            <circle cx="16" cy="12" r="6" stroke="gold" strokeWidth="2" />
            <polygon points="12,4 14,8 10,8" fill="orange" />
          </svg> */}
          <img src='https://www.giva.co/cdn/shop/files/GDLR0128_5.jpg?v=1689855533&width=713' className='Jewellery-icon'/>
          <p>RING</p>
        </div>

        <div className="jewellery-item">
          {/* Necklace SVG */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="jewellery-icon">
            <circle cx="12" cy="10" r="8" stroke="gold" strokeWidth="2" />
            <rect x="11" y="12" width="2" height="4" fill="red" />
          </svg> */}
           <img src={Necklace} className="jewellery-icon"/>
          <p>Necklace Set</p>
        </div>

        <div className="jewellery-item">
          {/* Earrings SVG */}
          {/* <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="none" className="jewellery-icon">
            <circle cx="10" cy="8" r="2" fill="red" />
            <circle cx="14" cy="8" r="2" fill="red" />
            <polygon points="8,10 12,18 16,10" fill="orange" />
          </svg> */}
            <img src={Earring} className="jewellery-icon"/>
          <p>Earrings</p>
        </div>
      </div>
     
    </div>
    <div className="giva-container">
      <h2>Afro Essentials</h2>
      <div className="giva-grid">
        <div className="giva-item">
          <div className="giva-image-wrapper">
            <img
              src="https://www.giva.co/cdn/shop/files/desktop-min.jpg?v=1727426964&width=1250"
              alt="Gold with Lab Diamonds"
              className="giva-image"
            />
            <span className="giva-tag">0% Making Charges</span>
          </div>
          <p>Gold with Lab Diamonds</p>
        </div>
        <div className="giva-item">
          <img
            src="https://www.giva.co/cdn/shop/files/Silver_17_-min.jpg?v=1724150688&width=1250"
            alt="Silver Jewellery"
            className="giva-image"
          />
          <p>Silver Jewellery</p>
        </div>
      </div>
    </div>

    
    </div>
  
  );
};

export default WelcomeSection;
