import React from 'react';
import { Link } from 'react-router-dom';
import Royal1 from '../Jewelleryimg/Royal Ganesha Set1.png';
import Divine1 from '../Jewelleryimg/Divine Lakshmi Set1.png';
import Gemstone1 from '../Jewelleryimg/Gemstone Gold Set1.png';
import Gold1 from '../Jewelleryimg/Gold Beaded Set1.png';
import Antique1  from '../Jewelleryimg/Antique Gold Set1.png';
import Elegance1  from '../Jewelleryimg/Elegance Set1.png';
import Luxe1  from '../Jewelleryimg/Luxe Gold Duo1.png';    
import cover from '../IMAGE/afrocoverpage.png'             

const ADjewellery = () => {
  const products = [
    { img1: Royal1, alt: "Royal Ganesha Set", label: "Royal Ganesha Set",sku:"ANSGNK29403",whatsapp:" ",route:"/Royal"},
    { img1: Divine1, alt: "Divine Lakshmi Set", label: "Divine Lakshmi Set",sku:"ANSGNK25399",whatsapp:" ",route:"/Divine"}, 
    { img1: Gemstone1, alt: "Gemstone Gold Set", label: "Gemstone Gold Set",sku:"ANSGNK05378",whatsapp:" ",route:"/Gemstone"},
    { img1: Gold1, alt: "Gold Beaded Set", label: "Gold Beaded Set",sku:"ANSGNK09359",whatsapp:" ",route:"/Gold"},   
    { img1: Antique1, alt: "Antique Gold Set", label: "Antique Gold Set",sku:"ANSGNK12386",whatsapp:" ",route:"/Antique"},
    { img1: Elegance1, alt: "Elegance Set", label: "Elegance Set",sku:"NFADNK127",whatsapp:" ",route:"/Elegance"},
    { img1: Luxe1, alt: "Luxe Gold Duo", label: " Luxe Gold Duo ",sku:"ANSCNK11",whatsapp:" ",route:"/Luxe"},
  ];

  return (
    <div>
      <div className='home-page'>
        <img 
          src={cover} 
          className="w-full mt-10 object-cover" 
          alt="Jewellery" 
        />
      </div>
      
      <div className="p-1 flex flex-wrap items-center justify-center min-h-screen">
        {products.map((product, index) => (
          <div key={index} className="flex-shrink-0 m-6 relative overflow-hidden bg-indigo-800 rounded-lg max-w-xs shadow-lg group">
            <svg className="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-[1.65] transition-transform"
              viewBox="0 0 375 283" fill="none" style={{ opacity: 0.1 }}>
              <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
              <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
            </svg>
            <div className="relative pt-0 px-0 flex items-center justify-center group-hover:scale-110 transition-transform">
              <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
                style={{ background: 'radial-gradient(black, transparent 60%)', transform: 'rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1)', opacity: 0.2 }}>
              </div>
              <Link to={product.route}><img className="relative w-80 h-auto" src={product.img1} alt={product.alt} /></Link>
            </div>
            <div className="relative text-white px-6 pb-6 mt-6">
              <div className="flex justify-between">
              <Link to={product.route}> <span className="block font-semibold text-xl">{product.label}</span> </Link>
                <a href={product.whatsapp}>
                  <span className="bg-black rounded-full text-white text-sm font-medium px-3 py-2 leading-none flex items-center">
                    Get Price
                  </span>
                </a>

              </div>
            </div>
          </div>
        ))}
      </div>

      
    </div>
  );
}

export default ADjewellery;
