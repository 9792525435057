import React from 'react';
import { Link } from 'react-router-dom';
import Floral1 from '../Jewelleryimg/Floral Harmonys1.png';
import Verdant1 from '../Jewelleryimg/Verdant Elegance1.png';
import Nature1 from '../Jewelleryimg/Natures Elegance1.png';
import cover from '../IMAGE/afrocoverpage.png'  
const ADjewellery = () => { 
  const products = [
    { img1: Floral1, alt: "Floral Harmonys", label: "Floral Harmony",sku:"SLSJNK01319",whatsapp:"",route:"/Floral"},
    { img1: Verdant1, alt: "Verdant Elegance", label: "Verdant Elegance",sku:"SLSJNK02320",whatsapp:"",route:"/Verdant"},
    { img1: Nature1, alt: "Nature's Elegance", label: "Nature's Elegance",sku:"SLSJNK03321",whatsapp:" ",route:"/Nature"},
  
  ];  

  return (
    <div>
      <div className='home-page'>
        <img 
          src={cover}
          className="w-full mt-10 object-cover" 
          alt="Jewellery" 
        />
      </div>
      
      <div className="p-1 flex flex-wrap items-center justify-center min-h-screen">
        {products.map((product, index) => (
          <div key={index} className="flex-shrink-0 m-6 relative overflow-hidden bg-indigo-800 rounded-lg max-w-xs shadow-lg group">
            <svg className="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-[1.65] transition-transform"
              viewBox="0 0 375 283" fill="none" style={{ opacity: 0.1 }}>
              <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
              <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
            </svg>
            <div className="relative pt-0 px-0 flex items-center justify-center group-hover:scale-110 transition-transform">
              <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
                style={{ background: 'radial-gradient(black, transparent 60%)', transform: 'rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1)', opacity: 0.2 }}>
              </div>
              <Link to={product.route}><img className="relative w-80 h-auto" src={product.img1} alt={product.alt} /></Link>
            </div>
            <div className="relative text-white px-6 pb-6 mt-6">
              <div className="flex justify-between">
              <Link to={product.route}> <span className="block font-semibold text-xl">{product.label}</span> </Link>
                <a href={product.whatsapp}>
                  <span className="bg-black rounded-full text-white text-sm font-medium px-3 py-2 leading-none flex items-center">
                    Get Price
                  </span>
                </a>

              </div>
            </div>
          </div>
        ))}
      </div>      
    </div>
  );
}

export default ADjewellery;
