import React from 'react'
import './Contact.css'
import Footer from '../Footer/Footer.tsx';
const Contact = () => {
  const hours = [
    { day: "Monday - Friday", time: "10:00 AM - 7:00 PM" },
  ];

  return (
    <div className='location-sec'>

<div className="map-container">
    <iframe
      src="https://www.google.com/maps/embed?pb=..."
      allowFullScreen=""
      loading="lazy"
      referrerPolicy="no-referrer-when-downgrade"
      title="Google Map"
      className="responsive-iframe"
    ></iframe>
  </div>
  <div className="contact-container">
      <div className="contact-info">
        <div className="contact-item">
          <i className="fas fa-map-marker-alt icon"></i>
          <div>
            <h3>Location</h3>
            <p>Showroom #02, Abdul Kareem, AL Abdul building, Geepas tower, behind giant supermarket, Rumaila 01, Ajman - U.A.E</p>
          </div>
        </div>
        <div className="contact-item">
            <i className="fas fa-clock icon"></i>
            <div>
              <h3>Open Hours</h3>
              <div className="opening-hours-container">
      {/* <i className="fas fa-clock icon"></i> */}
      <div>
        {/* <h3 className="heading">Open Hours</h3> */}
        {hours.map((entry, index) => (
          <p key={index} className="time-entry">
            {entry.day}: <span>{entry.time}</span>
          </p>
        ))}
      </div>
    </div>
            </div>
          </div>
        <div className="contact-item">
          <i className="fas fa-phone-alt icon"></i>
          <div>
            <h3>Call Us</h3>
            <p>+971-5546043125</p>
          </div>
        </div>
        <div className="contact-item">
          <i className="fas fa-envelope icon"></i>
          <div>
            <h3>Email Us</h3>
            <p>askafroasiatic@gmail.com</p>
          </div>
        </div>
      </div>
      <div className="contact-form">
        <form>
          <div className="form-row">
            <input type="text" placeholder="Your Name" required />
            <input type="email" placeholder="Your Email" required />
          </div>
          <input type="text" placeholder="Subject" required />
          <textarea placeholder="Message" rows="5" required></textarea>
          <button type="submit">Send Message</button>
        </form>
      </div>
    </div>
    <Footer/>
    </div>
   
  )
}

export default Contact