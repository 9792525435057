
import React from 'react';
import './App.css';
import Navbar from './Navbar/Navbar.tsx';

import WelcomeSection from './Middlesec/WelcomeSection.js';
import Introsec from './Introsec/Introsec.js';
import Jewellerysec from './Jewellerysec/jewellerysec.js'
import Footer from './Footer/Footer.tsx';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import About from './ABOUT/About.js';
import Contact from './Contact/Contact.js';
import Categories from './Categories/Categories.js';
import Gallery from './Gallery/Gallery.tsx';
import ADjewellery from './ADJewellery/ADjewellery.tsx';
import KD from './KundanJewellery/KD.tsx';
/* 
import GDF from './GoldForming/GDF.tsx';

import TPJ from './TempleJewellery/TPJ.tsx';  */
import RS from './Realsilver/RS.tsx';
import AQJ from './AnitiqueJewellery/AQJ.tsx';
import Crystal from './ADJewellery/ADsubpro/Crystal.tsx';
import Leaf from './ADJewellery/ADsubpro/Leaf.tsx';
import Ruby from './ADJewellery/ADsubpro/Ruby.tsx';
import Sapphire from './ADJewellery/ADsubpro/sapphire.tsx';
import Sparkle from './ADJewellery/ADsubpro/Sparkle.tsx';
import Royal from './AnitiqueJewellery/Antiquesubprod/Royal.tsx';
import Divine from './AnitiqueJewellery/Antiquesubprod/Divine.tsx';
import Gold from './AnitiqueJewellery/Antiquesubprod/Gold.tsx';
import Antique from './AnitiqueJewellery/Antiquesubprod/Antique.tsx';
import Elegance from './AnitiqueJewellery/Antiquesubprod/Elegance.tsx';
import Gemstone from './AnitiqueJewellery/Antiquesubprod/Gemstone.tsx';
import Luxe from './AnitiqueJewellery/Antiquesubprod/luxe.tsx';
import Nature from './Realsilver/RSprod/Nature.tsx';
import Verdant from './Realsilver/RSprod/Verdant.tsx';
import Floral from './Realsilver/RSprod/Floral.tsx';
import Pure from './KundanJewellery/kundanprod/Pure.tsx';
import Azure from './KundanJewellery/kundanprod/Azure.tsx';
import Celestial from './KundanJewellery/kundanprod/Celestial.tsx';
import Crimson from './KundanJewellery/kundanprod/Crimson.tsx';
import Dazzeling from './KundanJewellery/kundanprod/Dazzeling.tsx';
import Maroon from './KundanJewellery/kundanprod/Maroon.tsx';
import Timeless from './KundanJewellery/kundanprod/Timeless.tsx';

function App() {
  return (
    <> 

<BrowserRouter>
<Navbar/>
<Routes>
<Route index element ={<WelcomeSection/>}/>
  <Route path='/WelcomeSection' element ={<WelcomeSection/>}/>
  <Route path='/Categories' element={<Categories/>}/>
  <Route path='/ADjewellery' element={<ADjewellery/>}/>
{/*   
  <Route path='/Goldformingjewellery' element={<GDF/>}/>

  <Route path='/Templejewellery' element={<TPJ/>}/>  */}
   <Route path='/Kundanjewellery' element={<KD/>}/>
    <Route path='/Realsilver' element={<RS/>}/>
  <Route path='/Antiquejewellery' element={<AQJ/>}/>
  <Route path='/About' element = {<About/>} />
  <Route path='/Gallery' element = {<Gallery/>} />
  <Route path='/Contact' element = {<Contact/>} />
  <Route path='/Crystal' element = {<Crystal/>} />
  <Route path='/Leaf' element = {<Leaf/>} />
  <Route path='/Ruby' element = {<Ruby/>} />
  <Route path='/Sapphire' element = {<Sapphire/>} />
  <Route path='/Sparkle' element = {<Sparkle/>} />
  <Route path='/Royal' element = {<Royal/>} />
  <Route path='/Divine' element = {<Divine/>} />
  <Route path='/Gold' element = {<Gold/>} />
  <Route path='/Antique' element = {<Antique/>} />
  <Route path='/Elegance' element = {<Elegance/>} />
  <Route path='/Luxe' element = {<Luxe/>} />
  <Route path='/Gemstone' element = {<Gemstone/>} />
  <Route path='/Nature' element = {<Nature/>} />
  <Route path='/Verdant' element = {<Verdant/>} />
  <Route path='/Floral' element = {<Floral/>} />
  <Route path='/Celestial' element = {<Celestial/>} />
  <Route path='/Crimson' element = {<Crimson/>} />
  <Route path='/Dazzeling' element = {<Dazzeling/>} />
  <Route path='/Maroon' element = {<Maroon/>} />
  <Route path='/Timeless' element = {<Timeless/>} />
  <Route path='/Pure' element = {<Pure/>} />
  <Route path='/Azure' element = {<Azure/>} />
</Routes>
<Footer/>
</BrowserRouter>

    </>
  );
}

export default App;
