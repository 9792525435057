import React from 'react'
import './Categories.css'
import { Link } from 'react-router-dom'
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Bangles from '../Jewelleryimg/Bangles.JPG';
import Footer from '../Footer/Footer.tsx';
const Categories = () => {
  return (
    <div>
        
        <div className='poster'>
            <img src='https://www.tanishq.co.in/on/demandware.static/-/Library-Sites-TanishqSharedLibrary/default/dwb3ca07a4/microsite/joy-of-dressing/into-eternity-banners-desktop.jpg' alt='poster-img'/>
        </div>
    
    <div className='nav-container'>
    <Stack spacing={10} direction="row" >
      <Button variant="outlined"><a>All Category</a></Button>
      <Button variant="outlined"><a>American Diamond Jewellery</a></Button>
      <Button variant="outlined"><a>Kundan Jewellery</a></Button>
      <Button variant="outlined"><a>Oxidized Jewellery</a></Button>
      <Button variant="outlined"><a>Antique Jewellery</a></Button>
      <Button variant="outlined"><a>Temple Jewellery</a></Button>
    </Stack>
    </div>
    <div className="grid grid-rows-3 grid-flow-col gap-7">
  <div><img src={Bangles}/></div>
  <div><img src={Bangles}/></div>
  <div><img src={Bangles}/></div>
  <div><img src={Bangles}/></div>
  <div><img src={Bangles}/></div>
    <div><img src={Bangles}/></div>
  <div><img src={Bangles}/></div>
  <div><img src={Bangles}/></div>
  <div><img src={Bangles}/></div>


</div>
<Footer/>
    </div>
  )
}

export default Categories