import React from 'react';
import { Link } from 'react-router-dom';
import Crystal1 from '../Jewelleryimg/Crystal Drops1.png';
import Leaf1 from '../Jewelleryimg/Leaf Luxe Set1.png';
import Ruby1 from '../Jewelleryimg/Ruby Glow1.png';
import Sapphire1 from '../Jewelleryimg/Sapphire Glow1.png';
import Sparkle1  from '../Jewelleryimg/Sparkle Halo Set1.png';    
import cover from '../IMAGE/afrocoverpage.png'  

const ADjewellery = () => {
  const products = [
    { img1: Crystal1, alt: "Crystal Drops", label: "Crystal Drops",sku:"ADRBNK25",whatsapp:"https://api.whatsapp.com/send?phone=971554604312&text=I%27m%20interested%20in%20knowing%20the%20price%20of%20the%20product-ADRBNK25%0A%0A",route:"/Crystal"},
    { img1: Leaf1, alt: "Leaf Luxe Set", label: "Leaf Luxe Set",sku:"RBJADNK634",whatsapp:"https://wa.me/+971554604312?text=I'm%20interested%20in%20knowing%20the%20price%20of%20the%20product-RBJADNK634",route:"/Leaf"},
    { img1: Ruby1, alt: "Ruby Glow", label: "Ruby Glow",sku:"NFADNK",whatsapp:" ",route:"/Ruby"},
    { img1: Sapphire1, alt: "Sapphire Glow", label: "Sapphire Glow",sku:"ADBJNK42171",whatsapp:" ",route:"/Sapphire"},
    { img1: Sparkle1, alt: "Sparkle Halo Set", label: "Sparkle Halo Set",sku:"ADBJNK30",whatsapp:" ",route:"/Sparkle"},  
  ];

  return (
    <div>
      <div className='home-page'>
        <img 
          src={cover} 
          className="w-full mt-10 object-cover" 
          alt="Jewellery" 
        />
      </div>
      
      <div className="p-1 flex flex-wrap items-center justify-center min-h-screen">
        {products.map((product, index) => (
          <div key={index} className="flex-shrink-0 m-6 relative overflow-hidden bg-indigo-800 rounded-lg max-w-xs shadow-lg group">
            <svg className="absolute bottom-0 left-0 mb-8 scale-150 group-hover:scale-[1.65] transition-transform"
              viewBox="0 0 375 283" fill="none" style={{ opacity: 0.1 }}>
              <rect x="159.52" y="175" width="152" height="152" rx="8" transform="rotate(-45 159.52 175)" fill="white" />
              <rect y="107.48" width="152" height="152" rx="8" transform="rotate(-45 0 107.48)" fill="white" />
            </svg>
            <div className="relative pt-0 px-0 flex items-center justify-center group-hover:scale-110 transition-transform">
              <div className="block absolute w-48 h-48 bottom-0 left-0 -mb-24 ml-3"
                style={{ background: 'radial-gradient(black, transparent 60%)', transform: 'rotate3d(0, 0, 1, 20deg) scale3d(1, 0.6, 1)', opacity: 0.2 }}>
              </div>
              <Link to={product.route}><img className="relative w-80 h-auto" src={product.img1} alt={product.alt} /></Link>
            </div>
            <div className="relative text-white px-6 pb-6 mt-6">
              <div className="flex justify-between">
              <Link to={product.route}> <span className="block font-semibold text-xl">{product.label}</span> </Link>
                <a href={product.whatsapp}>
                  <span className="bg-black rounded-full text-white text-sm font-medium px-3 py-2 leading-none flex items-center">
                    Get Price
                  </span>
                </a>

              </div>
            </div>
          </div>
        ))}
      </div>

      
    </div>
  );
}

export default ADjewellery;
