import React, { useEffect } from 'react';
import Images from '../IMAGE/Logos.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import ScrollReveal from 'scrollreveal';
import { FaFacebookF, FaInstagram } from "react-icons/fa6";
import { BsTwitterX } from "react-icons/bs";
import { ImLinkedin2 } from "react-icons/im";

const Footer = () => {
  useEffect(() => {
    // Initialize ScrollReveal
    ScrollReveal().reveal('.reveal', {
      distance: '50px',
      duration: 1000,
      easing: 'ease-in-out',
      origin: 'bottom',
      reset: true,
    });
  }, []);

  return (
    <footer className="bg-black reveal">
      <div className="mx-auto max-w-screen-xl space-y-8 px-4 py-8 sm:px-6 lg:px-8">
        <div className="grid grid-cols-1 gap-8 lg:grid-cols-3 sm:grid-cols-2 md:grid-cols-2">
          {/* Logo Section */}
          <div className="text-teal-600 flex justify-center lg:justify-start">
            <img
              src={Images}
              alt="Logo"
              className="md:w-auto md:h-auto w-60 h-auto"
              style={{ marginTop: '30px' }}
            />
          </div>

          {/* Quick Links and Contact */}
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:col-span-2">
            <div className="grid grid-cols-2 gap-6">
              {/* Quick Links */}
              <div>
                <p className="font-medium text-white">Quick Links</p>
                <ul className="mt-6 space-y-3 text-sm">
                  <li>
                    <a href="#" className="text-white hover:opacity-75 hover:pl-2 transition-all">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white hover:opacity-75 hover:pl-2 transition-all">
                      About Us
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white hover:opacity-75 hover:pl-2 transition-all">
                      Categories
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white hover:opacity-75 hover:pl-2 transition-all">
                      Blog
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white hover:opacity-75 hover:pl-2 transition-all">
                      Gallery
                    </a>
                  </li>
                </ul>
              </div>

              {/* Jewelry Links */}
              <div>
                <ul className="mt-14 space-y-3 text-sm">
                  <li>
                    <a href="#" className="text-white hover:opacity-75 hover:pl-2 transition-all">
                      American Diamond Jewellery
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white hover:opacity-75 hover:pl-2 transition-all">
                      Kundan Jewellery
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white hover:opacity-75 hover:pl-2 transition-all">
                      Gold Form Jewellery
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white hover:opacity-75 hover:pl-2 transition-all">
                      Real Silver Jewellery
                    </a>
                  </li>
                  <li>
                    <a href="#" className="text-white hover:opacity-75 hover:pl-2 transition-all">
                      Antique Jewellery
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            {/* Contact Us */}
            <div>
              <p className="font-medium text-white">Contact Us</p>
              <ul className="mt-6 space-y-4 text-sm">
                <li>
                  <FontAwesomeIcon icon={faEnvelope} className="text-yellow-500" />
                  <a href="mailto:askafro@gmail.com" className="hover:text-yellow-500 ml-2">
                    askafro@gmail.com
                  </a>
                </li>
                <li>
                  <FontAwesomeIcon icon={faPhone} className="text-yellow-500" />
                  <a href="tel:+971-545667046/+971-554604312" className="hover:text-yellow-500 ml-2">
                    +971-545667046 / +971-554604312
                  </a>
                </li>
                <li>
                  <p className="text-white text-sm">
                    Showroom #02, Abdul Kareem, AL Abdul building, Geepas tower, behind giant supermarket, Rumaila 01, Ajman - U.A.E
                  </p>
                </li>
              </ul>
            </div>
          </div>
        </div>

        {/* Social Media Icons */}
        <div className="flex gap-3 justify-center mt-4">
          <FaInstagram className="text-2xl hover:scale-125 transition-transform text-white" />
          <FaFacebookF className="text-2xl hover:scale-125 transition-transform text-white" />
          <BsTwitterX className="text-2xl hover:scale-125 transition-transform text-white" />
          <ImLinkedin2 className="text-2xl hover:scale-125 transition-transform text-white" />
        </div>

        {/* Footer Bottom */}
        <hr className="my-4 border-white" />
        <span className="text-white flex justify-center tracking-wide text-center">
          &copy; 2024 
          <a href="#" className="hover:underline pl-1">
            Afro Asiatic Fusion Art Jewellery Dubai
          </a>. All rights reserved
        </span>
      </div>
    </footer>
  );
};

export default Footer;
