import React, { useEffect } from 'react'
import ScrollReveal from 'scrollreveal';
import './About.css'
import Footer from '../Footer/Footer.tsx'
import Aboutimg from "../IMAGE/AFRO.png";
const About = () => {
  useEffect(() => {
    // Initialize ScrollReveal
    ScrollReveal().reveal('.reveal', {
      distance: '50px',    // Distance to move the element
      duration: 1000,      // Duration of the animation in milliseconds
      easing: 'ease-in-out', // Easing function for the animation
      origin: 'bottom',    // Direction from which the element will appear ('top', 'right', 'bottom', 'left')
      reset: true,         // Whether to reset animation when the element is scrolled out of view
    });
     // Reveal for elements with class 'slide-in-left'
     ScrollReveal().reveal('.slide-in-left', {
      distance: '100px',
      duration: 1200,
      easing: 'ease-out',
      origin: 'top',
      delay: 200, // Delay the animation by 200ms
      reset: true, // Only animate once
    });

    ScrollReveal().reveal('.slide-left', {
      distance: '100px',
      duration: 1200,
      easing: 'ease-out',
      origin: 'left',
      delay: 200, // Delay the animation by 200ms
      reset: true, // Only animate once
    });

    ScrollReveal().reveal('.slide-right', {
      distance: '100px',
      duration: 1200,
      easing: 'ease-out',
      origin: 'right',
      delay: 200, // Delay the animation by 200ms
      reset: true, // Only animate once
    });
  }, []);  // Empty dependency array to run only once
  return (
    <div>
     
          <div className="hero-section" >
         <img src={Aboutimg} alt='Necklaces' />
      
     
    </div> 
    <div className="bg-black text-white py-12 px-2">
      <div className="max-w-4xl mx-auto text-center slide-in-left">
        <h2 className="text-4xl font-bold mb-6 ">About Us</h2>
        <p className="text-lg leading-relaxed mb-6 ">
          Afroasiatic Fusion Art Jewellery is a largest manufacturer, wholesaler, exporter,
          supplier, stockist of High Quality Fashion Jewellery. We provide exquisite jewellery from
          highly talented designers. We bring you a wide range of designer fusion art jewellery
          which is a blend of superior quality, beautiful design & excellent craftsmanship. Our
          collection of fashionable jewellery has been designed by expert designers and handcrafted
          by the most talented artisans.
        </p>
        <a
          href="/shop"
          className="text-yellow-500 font-semibold text-lg underline hover:text-yellow-400"
        >
          Shop Now
        </a>
      </div>
    </div>
    <section className="relative bg-black text-white py-16 px-2">
      <div className="max-w-7xl mx-auto grid md:grid-cols-2 gap-8 reveal">
        {/* Feature 1: Top Center */}
        <div className="flex flex-col items-center justify-center slide-left">
          <img 
            src="https://afroasiaticfusionartjewellerydubai.com/assets/img/p8.png" 
            alt="Heart Necklace" 
            className="w-48 mb-8 " 
          />
          <h2 className="text-3xl font-semibold mb-4">Quick Response</h2>
          <p className="text-center ">
            Our dedicated response team works round the clock for a lightning-quick response to your requirements.
          </p>
        </div>

        {/* Empty Space for the right side at the top */}
        <div></div>

        {/* Feature 2: Positioned at the Bottom Right */}
        <div className="absolute top-96 right-16 flex flex-col items-center slide-right px-0">
          <img 
            src="https://afroasiaticfusionartjewellerydubai.com/assets/img/p2.png" 
            alt="Gold Necklace" 
            className="w-48 mb-8" 
          />
          <h2 className="text-3xl font-semibold mb-4">Genuine Quality</h2>
          <p className="text-center max-w-md">
            We have various eminent distributors & valuable customers all over the world. Our team has successfully accomplished innumerable international tenders in the past.
          </p>
        </div>
        
        <div className="flex flex-col items-center justify-center mt-96 slide-left px-0">
          <img 
            src="https://afroasiaticfusionartjewellerydubai.com/assets/img/p3.png" 
            alt="Pearl Necklace" 
            className="w-48 mb-8" 
          />
          <h2 className="text-3xl font-semibold mb-4">24/7 Support</h2>
          <p className="text-center ">
          Each of our customers shall receive superior value through timely 24/7 customer service. We are with you all the way right from start to end.
          </p>
        </div>
      </div>
       {/* Bottom Section */}
       <div className="mt-16 text-center reveal px-0">
          {/* Text Section */}
          <h2 className="text-4xl font-semibold mb-4">Afroasiatic Fusion Accessories</h2>
          <p className="max-w-4xl mx-auto">
            Our aim is to provide our customers the best services & the best designer fusion jewelry collection of high quality. Our first priority is customer satisfaction. We use only premium quality raw materials in the manufacturing of all our products. We have a well-equipped plant with the latest and advanced technology where we manufacture fashion/costume imitation jewelry of international quality.
            <br />
            <br />
            Afroasiatic is the brand leader of Fusion Art Jewelry. We hope that with your love & response we shall continue to serve you with exclusively designed jewelry always. Start shopping now & pick up the most enticingly designed jewelry to beautify yourself or gift to your loved ones.
          </p>
        </div>
    </section>
    <div>
    
    </div>

    </div>
   
  )
}

export default About