import React, { useState } from 'react';
import Image from "../IMAGE/Logos.svg";
import { Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEnvelope, faPhone } from '@fortawesome/free-solid-svg-icons';
import { faFacebook, faInstagram } from '@fortawesome/free-brands-svg-icons';

const Navbar: React.FC = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false); // New state for dropdown

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };

  const toggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen); // Toggle dropdown
  };

  return (
    <>
      {/* Top bar with contact info */}
      <div className="bg-black text-white py-2 flex justify-between items-center text-sm px-1 sm:px-1 md:px-2 lg:px-16">
        <div className="flex items-center space-x-4">
          <div className="flex items-center space-x-2">
            <FontAwesomeIcon icon={faEnvelope} className="text-yellow-500" />
            <a href="mailto:askafro@gmail.com" className="hover:text-yellow-500">
              askafro@gmail.com
            </a>
          </div>
          <div className="flex items-center space-x-2">
            <FontAwesomeIcon icon={faPhone} className="text-yellow-500" />
            <a href="tel:+971554604312" className="hover:text-yellow-500">
              +971-554604312
            </a>
          </div>
        </div>
        <div className="flex gap-6 space-x-2">
          <span className="relative">
            <FontAwesomeIcon
              icon={faInstagram}
              className="text-white cursor-pointer hover:text-[#ca9802]"
              size="2x"
            />
          </span>
          <span className="relative">
            <FontAwesomeIcon
              icon={faFacebook}
              className="text-white cursor-pointer hover:text-[#ca9802]"
              size="2x"
            />
          </span>
        </div>
      </div>

      {/* Main navbar */}
      <header className="shadow-md bg-black font-[sans-serif] tracking-wide relative z-50 text-white text-sm px-16 sm:px-0 md:px-0 lg:px-16 py-0">
        <section className="flex items-center justify-between lg:justify-between p-0.5">
          <Link to="/WelcomeSection" className="shrink-0">
            <img src={Image} alt="logo" className="md:w-[400px] w-40 h-auto" />
          </Link>

          {/* Desktop navigation */}
          <div className="hidden lg:flex lg:flex-row lg:gap-x-10 lg:items-center lg:ml-auto">
            <ul className="flex space-x-10">
              <li>
                <Link to='/WelcomeSection' className="hover:text-[#bd8c39] font-semibold text-white">Home</Link>
              </li>
              <li className="relative">
                <li onClick={toggleDropdown} className="hover:text-[#cba33e] font-semibold text-white flex items-center">
                  Categories
                  <svg className="ml-1 w-4 h-4" fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
                    <path fillRule="evenodd" d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 011.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clipRule="evenodd"></path>
                  </svg>
                </li>
                {/* Dropdown */}
                <ul className={`absolute left-0 mt-2 ${isDropdownOpen ? "block" : "hidden"} bg-white text-black shadow-lg space-y-2 py-2 rounded-md w-56 z-50`}>
                  <li className="px-4 py-2">
                    <Link to='/ADjewellery' className="hover:text-[#cba33e] font-semibold">American Diamond Jewellery</Link>
                  </li>
                  <li className="px-4 py-2">
                    <Link to='/Kundanjewellery' className="hover:text-[#cba33e] font-semibold">Kundan Jewellery</Link>
                  </li>
                  <li className="px-4 py-2">
                    <Link to='/Goldformingjewellery' className="hover:text-[#cba33e] font-semibold">Gold Form Jewellery</Link>
                  </li>
                  <li className="px-4 py-2">
                    <Link to='/Realsilver' className="hover:text-[#cba33e] font-semibold">Real Silver Jewellery</Link>
                  </li>
                  <li className="px-4 py-2">
                    <Link to='/Antiquejewellery' className="hover:text-[#cba33e] font-semibold">Antique Jewellery</Link>
                  </li> 
                </ul>
              </li>
              <li>
                <Link to='/About' className="hover:text-[#c5a13d] font-semibold text-white">About Us</Link>
              </li>
              <li>
                <Link to='/Gallery' className="hover:text-[#c5a13d] font-semibold text-white">Gallery</Link>
              </li>
              <li>
                <Link to='/Contact' className="hover:text-[#c5a13d] font-semibold text-white">Contact</Link>
              </li>
            </ul>
          </div>

          {/* Hamburger icon for mobile */}
          <div className="lg:hidden">
            <button onClick={toggleMenu} aria-label="Toggle Menu">
              <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h16M4 18h16"></path>
              </svg>
            </button>
          </div>
        </section>

        {/* Mobile navigation */}
        <div className={`${isMenuOpen ? "block" : "hidden"} lg:hidden`}>
          <ul className="flex flex-col space-y-4">
            <li>
              <Link to='/WelcomeSection' className="hover:text-[#bd8c39] font-semibold text-white">Home</Link>
            </li>
            <li className="relative">
              <li onClick={toggleDropdown} className="hover:text-[#cba33e] font-semibold text-white">
                Categories
              </li>
              {/* Mobile Dropdown */}
              <ul className={`absolute left-0 mt-2 ${isDropdownOpen ? "block" : "hidden"} bg-white text-black shadow-lg space-y-2 py-2 rounded-md w-56 z-50`}>
                <li className="px-4 py-2">
                  <Link to='/ADjewellery' className="hover:text-[#cba33e] font-semibold">American Diamond Jewellery</Link>
                </li>
                <li className="px-4 py-2">
                  <Link to='/Kundanjewellery' className="hover:text-[#cba33e] font-semibold">Kundan Jewellery</Link>
                </li>
                <li className="px-4 py-2">
                  <Link to='/Goldformingjewellery' className="hover:text-[#cba33e] font-semibold">Gold Form Jewellery</Link>
                </li>
                <li className="px-4 py-2">
                  <Link to='/Realsilver' className="hover:text-[#cba33e] font-semibold">Real Silver Jewellery</Link>
                </li>
                <li className="px-4 py-2">
                  <Link to='/Antiquejewellery' className="hover:text-[#cba33e] font-semibold">Antique Jewellery</Link>
                </li>
                
              </ul>
            </li>
            <li>
              <Link to='/About' className="hover:text-[#c5a13d] font-semibold text-white">About Us</Link>
            </li>
            <li>
              <Link to='/Gallery' className="hover:text-[#c5a13d] font-semibold text-white">Gallery</Link>
            </li>
            <li>
              <Link to='/Contact' className="hover:text-[#c5a13d] font-semibold text-white">Contact</Link>
            </li>
          </ul>
        </div>
      </header>
    </>
  );
};

export default Navbar;
