import React, { useState } from 'react';

import Leaf1 from '../../Jewelleryimg/Leaf Luxe Set1.png';
import Leaf2 from '../../Jewelleryimg/Leaf Luxe Set2.png';
import Leaf3 from '../../Jewelleryimg/Leaf Luxe Set3.png';
import Leaf4 from '../../Jewelleryimg/Leaf Luxe Set4.png';

import { IoDiamondOutline } from "react-icons/io5";



const Leaf = () => {
  // Defining the array of products with multiple images
  const products = [
    { img1: Leaf1, img2: Leaf2, img3: Leaf3, img4: Leaf4, alt: "Leaf", label: "Leaf Drops",sku:"RBJADNK634", whatsapp:" ",desc:"Elevate your style with this stunning imitation jewelry set, featuring a necklace and earrings designed with intricate leaf patterns encrusted in shimmering crystals. The delicate cascading design adds a touch of opulence, making it the perfect choice for formal events, weddings, or special occasions. The timeless beauty of the leaf motif, combined with the brilliance of the crystals, offers an elegant and sophisticated charm"}
  ];

  // State for managing the main image display
  const [mainImage, setMainImage] = useState(products[0].img1);

  // Function to change the main image
  const changeImage = (src: string) => {
    setMainImage(src);
  };

  return (
    
<div className="flex items-center justify-center min-h-screen px-4 md:px-8 lg:px-16">
  <div className="container mx-auto py-8">
    <div className="flex flex-wrap justify-center items-start space-y-8 md:space-y-0 md:space-x-8">
      {/* Main Image Section */}
      <div className="w-full md:w-1/3 lg:w-1/3 px-4 mb-8">
        <img
          src={mainImage}
          alt="Product"
          className="w-full h-auto rounded-lg shadow-md mb-4"
          id="mainImage"
        />
        {/* Thumbnails */}
        <div className="flex gap-4 py-4 justify-center overflow-x-auto">
          {Object.keys(products[0])
            .filter((key) => key.startsWith('img'))
            .map((key, index) => (
              <img
                key={index}
                src={products[0][key as keyof typeof products[0]]}
                alt={`Thumbnail ${index + 1}`}
                className="w-16 h-16 sm:w-20 sm:h-20 object-cover rounded-md cursor-pointer opacity-60 hover:opacity-100 transition duration-300"
                onClick={() => changeImage(products[0][key as keyof typeof products[0]])}
              />
            ))}
        </div>
      </div>

      {/* Product Details Section */}
      <div className="w-full md:w-2/3 lg:w-2/3 px-4 mb-8">
        <div className="flex flex-col">
          {products.map((product, index) => (
            <div key={index} className="w-full mb-8">
              <h2 className="text-3xl font-bold mb-2">{product.label}</h2>
              <p className="text-white mb-4">SKU: {product.sku}</p>
              <p className="text-white mb-6">
               {product.desc}
              </p>
              <div className="flex space-x-4 mb-6 lg:pt-4">
                <button className="bg-indigo-600 flex gap-2 items-center text-white hover:text-white px-6 py-2 rounded-md hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                  <div className="text-2xl">
                    <IoDiamondOutline />
                  </div>
                  Get Price
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  </div>
</div>

 
  );
}
 


export default Leaf;